'use client';

import { useLayoutEffect, useState } from 'react';

import { type TUserRole, userRole } from '@shared/types';
import { getApiToken } from '@shared/api/utils/get-api-token';

export const useRole = () => {
	const [role, setRole] = useState<TUserRole>();
	useLayoutEffect(() => {
		const token = getApiToken();
		if (token) {
			// See entity root file for more info
			setRole(token.roles[0]);
		}
	}, []);
	return {
		role,
		isMarketing: role === userRole.marketing,
		isAffiliate: role === userRole.affiliate,
		isManager: role === userRole.manager,
	};
};
