import React, { useState, useMemo, useCallback } from 'react';

import { DropdownSelect } from '@shared/components/dropdown-select';
import { useDebouncedValue } from '@shared/hooks/use-debounced-value';

import { useAidList } from '../lib/use-aid-list';
import { type TAidsFilterType } from '../model/types';

import { AidListHeader } from './aid-list-header';

interface TAidListProps {
	onSelect: (selectedAids: number[]) => void;
}
/*
 * TODO: выделил как отдельный компонент, однако он получился statefull
 *  что не очень хорошо и вероятно, требует рефакторинга;
 * Некоторые данные дублируются здесь и в родительском компоненте
 * (selectedAidList например)
 * */

export const AidList: React.FC<TAidListProps> = ({ onSelect }) => {
	const [selectedAidList, setSelectedAidList] = useState<Set<number> | undefined>();
	const { initialAidsSet, aidOptions } = useAidList();

	const aidDropdownValue = useMemo(
		() => Array.from(selectedAidList ?? initialAidsSet),
		[initialAidsSet, selectedAidList],
	);

	const areAllSelected = aidOptions?.length === aidDropdownValue.length;

	const {
		value: searchString,
		setValue: setSearchString,
		debouncedValue: debouncedSearchString,
	} = useDebouncedValue<string>('', 300);

	const displayAidOptions = useMemo(
		() =>
			aidOptions?.filter(({ label }) =>
				label.toLowerCase().startsWith(debouncedSearchString.toLowerCase()),
			),
		[aidOptions, debouncedSearchString],
	);

	const handleDropdownSelect = useCallback(
		(selectedValue: number[]) => {
			setSelectedAidList(new Set(selectedValue));
			onSelect(selectedValue);
		},
		[onSelect],
	);

	const handleChangeAidsListHeader = useCallback(
		(type: TAidsFilterType) => {
			if (type === 'all') {
				handleDropdownSelect(displayAidOptions?.map(({ value }) => value) ?? []);
			}
		},
		[displayAidOptions, handleDropdownSelect],
	);

	if (!displayAidOptions) {
		return null;
	}

	return (
		<DropdownSelect<number>
			headerElement={
				<AidListHeader
					searchString={searchString}
					setSearchString={setSearchString}
					filterType={areAllSelected ? 'all' : 'custom'}
					onChange={handleChangeAidsListHeader}
				/>
			}
			dropdownValue={aidDropdownValue}
			onDropdownSelect={handleDropdownSelect}
			options={displayAidOptions}
			isMultiple
			iconType="filter"
			forcedPlaceholder={areAllSelected ? 'All affiliates' : 'Custom'}
			innerTitle="Affiliates"
			resetButton
		/>
	);
};
